import { Injectable } from '@angular/core';
import { BotResourceService } from './bot-resource.service';
import { Observable } from 'rxjs/internal/Observable';
import { GuildPremium } from '../models/bot/guild';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PremiumService {
  premiumObservable: Observable<GuildPremium>;

  constructor(private botService: BotResourceService) {}

  getPremiumInfo(guildId: string): Observable<GuildPremium> {
    if (!this.premiumObservable) {
      this.premiumObservable = this.botService
        .getPremiumInfo(guildId)
        .pipe(shareReplay(1));
    }
    return this.premiumObservable;
  }
}
