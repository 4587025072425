import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';import { ActivatedRoute } from '@angular/router';
import { faCheck, faShoppingCart, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { BotGuild, GuildPremium, SubscriptionPlanUpdate } from 'src/app/models/bot/guild';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { GuildService } from 'src/app/services/guild.service';
import { PremiumService } from 'src/app/services/premium.service';

declare const Paddle: any;

type SubscriptionType = 'basic' | 'advanced' | 'advancedPlus';

type SubscriptionItems = {
  monthly: string;
  yearly: string;
};

type FeatureItems = {
  name: string;
  enabled: boolean;
}

type PremiumPlan = {
  name: string;
  price: number;
  level: number;
  features: FeatureItems[];
  btnName: SubscriptionItems;
  price_id: SubscriptionItems;
}

type PremiumPlans = {
  [key in SubscriptionType]: PremiumPlan;
}

@Component({
  selector: 'app-premium-card',
  templateUrl: './premium-card.component.html',
  styleUrls: ['./premium-card.component.css'],
})
export class FastspringCardComponent implements OnInit {
  selectedTab: string = 'monthly'; // Default selected tab

  faIcons = {
    check: faCheck,
    cross: faTimes,
  };

  guildId: string;
  userId: number;
  userIdSubscription: Subscription;
  userEmail: string;
  guild: BotGuild | undefined;
  premiumInfo: GuildPremium;
  next_billing: string;

  premiumButtonDisabled: boolean = false;

  premiumPlans: PremiumPlans = {
    basic: {
      name: 'Basic',
      price: 3.99,
      level: 1,
      features: [
        // { name: 'Premium Role in Discord', enabled: true },
        // { name: 'Access to Premium Only Channel', enabled: true },
        { name: 'No Ads On Website', enabled: true },
        { name: 'Unlimited Submission Groups', enabled: true },
        { name: 'Custom Emojis', enabled: true },
        { name: 'Custom Submit Message', enabled: true },
        { name: 'Custom Color Ranges', enabled: true },
        { name: 'Third Party API', enabled: false },
        { name: 'Custom API', enabled: false },
        { name: 'Custom Bot', enabled: false },
      ],
      btnName: {
        monthly: 'Subscribe',
        yearly: 'Subscribe',
      },
      price_id: {
        // Sandbox
        // monthly: 'pri_01hqbtbf9dxfcht5jw87h9mm63',
        // yearly: 'pri_01hqbtcy6t3cwcnpey69embfx5',
        // Live
        monthly: 'pri_01hq55h6v1pe3ffknezxy0r0mg',
        yearly: 'pri_01hq55q7pn0aq2eb6f7zydv648',
      },
    },
    advanced: {
      name: 'Advanced',
      price: 7.99,
      level: 2,
      features: [
        // { name: 'Premium Role in Discord', enabled: true },
        // { name: 'Access to Premium Only Channel', enabled: true },
        { name: 'No Ads On Website', enabled: true },
        { name: 'Unlimited Submission Groups', enabled: true },
        { name: 'Custom Emojis', enabled: true },
        { name: 'Custom Submit Message', enabled: true },
        { name: 'Custom Color Ranges', enabled: true },
        { name: 'Third Party API', enabled: true },
        { name: 'Custom API', enabled: false },
        { name: 'Custom Bot', enabled: false },
      ],
      btnName: {
        monthly: 'Subscribe',
        yearly: 'Subscribe',
      },
      price_id: {
        // Sandbox
        // monthly: 'pri_01hqfk94b86zgmy10r8v0racaa',
        // yearly: 'pri_01hqfka7c1bcwy38dcst5e6tve',
        // Live
        monthly: 'pri_01hq55sccep2mf84fkvbwz6739',
        yearly: 'pri_01hq55v582mnjsbr6gj94483q9',
      },
    },
    advancedPlus: {
      name: 'Advanced+',
      price: 14.99,
      level: 3,
      features: [
        // { name: 'Premium Role in Discord', enabled: true },
        // { name: 'Access to Premium Only Channel', enabled: true },
        { name: 'No Ads On Website', enabled: true },
        { name: 'Unlimited Submission Groups', enabled: true },
        { name: 'Custom Emojis', enabled: true },
        { name: 'Custom Submit Message', enabled: true },
        { name: 'Custom Color Ranges', enabled: true },
        { name: 'Third Party API', enabled: true },
        { name: 'Custom API *', enabled: true },
        { name: 'Custom Bot *', enabled: true },
      ],
      btnName: {
        monthly: 'Subscribe',
        yearly: 'Subscribe',
      },
      price_id: {
        // Sandbox
        // monthly: 'pri_01hr2ryhxw411wp2m3ch7sp8bq',
        // yearly: 'pri_01hr2rznnes5c4vq3dk3t9q4hq',
        // Live
        monthly: 'pri_01hq55xbem3nj8fffnem88znda',
        yearly: 'pri_01hq55ycyyytk18wgr0wdwdjta',
      },
    },
  };

  selectTab(tab: string): void {
    this.selectedTab = tab;
    if (tab == 'yearly') {
      this.premiumPlans.basic.price = 39.99;
      this.premiumPlans.advanced.price = 79.99;
      this.premiumPlans.advancedPlus.price = 149.99;
    } else if (tab == 'monthly') {
      this.premiumPlans.basic.price = 3.99;
      this.premiumPlans.advanced.price = 7.99;
      this.premiumPlans.advancedPlus.price = 14.99;
    }
  }

  premiumPlanKeys: string[];
  constructor(
    private route: ActivatedRoute,
    private appComponent: AppComponent,
    public guildService: GuildService,
    private cdr: ChangeDetectorRef,
    private botService: BotResourceService,
    private dialog: MatDialog,
    private premiumService: PremiumService
  ) {}

  ngOnInit() {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');
    this.userIdSubscription = this.appComponent.userId.subscribe((userId) => {
      this.userId = userId;
    });

    this.premiumService.getPremiumInfo(this.guildId).subscribe((premium) => {
      this.premiumInfo = premium;

      const date = new Date(this.premiumInfo.next_billing);
      const userLocale = navigator.language || 'en-US'; // Get user's locale or fallback to 'en-US'
      this.next_billing = date.toLocaleDateString(userLocale);

      this.guildService.getGuildData(this.guildId).subscribe((guild) => {
        this.guild = guild;
        // this.guild.premium_lv = 3;
        this.updateButtonNames();
      });
    });

    // Get the keys of the premium plans to access them in HTML
    this.premiumPlanKeys = Object.keys(this.premiumPlans);
  }

  ngOnDestroy(): void {
    this.userIdSubscription.unsubscribe();
  }

  updateButtonNames() {
    if (!this.guild.premium_lv) return;
    if (
      this.premiumInfo.price_id === this.premiumPlans.basic.price_id.monthly
    ) {
      this.premiumPlans.basic.btnName['monthly'] = 'Cancel';
      this.premiumPlans.advanced.btnName['monthly'] = 'Upgrade';
      this.premiumPlans.advancedPlus.btnName['monthly'] = 'Upgrade';
      this.premiumPlans.basic.btnName['yearly'] = 'Change Plan';
      this.premiumPlans.advanced.btnName['yearly'] = 'Upgrade';
      this.premiumPlans.advancedPlus.btnName['yearly'] = 'Upgrade';
    } else if (
      this.premiumInfo.price_id === this.premiumPlans.advanced.price_id.monthly
    ) {
      this.premiumPlans.basic.btnName['monthly'] = 'Downgrade';
      this.premiumPlans.advanced.btnName['monthly'] = 'Cancel';
      this.premiumPlans.advancedPlus.btnName['monthly'] = 'Upgrade';
      this.premiumPlans.basic.btnName['yearly'] = 'Downgrade';
      this.premiumPlans.advanced.btnName['yearly'] = 'Change Plan';
      this.premiumPlans.advancedPlus.btnName['yearly'] = 'Upgrade';
    } else if (
      this.premiumInfo.price_id ===
      this.premiumPlans.advancedPlus.price_id.monthly
    ) {
      this.premiumPlans.basic.btnName['monthly'] = 'Downgrade';
      this.premiumPlans.advanced.btnName['monthly'] = 'Downgrade';
      this.premiumPlans.advancedPlus.btnName['monthly'] = 'Cancel';
      this.premiumPlans.basic.btnName['yearly'] = 'Downgrade';
      this.premiumPlans.advanced.btnName['yearly'] = 'Downgrade';
      this.premiumPlans.advancedPlus.btnName['yearly'] = 'Change Plan';
    } else if (
      this.premiumInfo.price_id === this.premiumPlans.basic.price_id.yearly
    ) {
      this.premiumPlans.basic.btnName['monthly'] = 'Change Plan';
      this.premiumPlans.advanced.btnName['monthly'] = 'Upgrade';
      this.premiumPlans.advancedPlus.btnName['monthly'] = 'Upgrade';
      this.premiumPlans.basic.btnName['yearly'] = 'Cancel';
      this.premiumPlans.advanced.btnName['yearly'] = 'Upgrade';
      this.premiumPlans.advancedPlus.btnName['yearly'] = 'Upgrade';
    } else if (
      this.premiumInfo.price_id === this.premiumPlans.advanced.price_id.yearly
    ) {
      this.premiumPlans.basic.btnName['monthly'] = 'Downgrade';
      this.premiumPlans.advanced.btnName['monthly'] = 'Change Plan';
      this.premiumPlans.advancedPlus.btnName['monthly'] = 'Upgrade';
      this.premiumPlans.basic.btnName['yearly'] = 'Downgrade';
      this.premiumPlans.advanced.btnName['yearly'] = 'Cancel';
      this.premiumPlans.advancedPlus.btnName['yearly'] = 'Upgrade';
    } else if (
      this.premiumInfo.price_id ===
      this.premiumPlans.advancedPlus.price_id.yearly
    ) {
      this.premiumPlans.basic.btnName['monthly'] = 'Downgrade';
      this.premiumPlans.advanced.btnName['monthly'] = 'Downgrade';
      this.premiumPlans.advancedPlus.btnName['monthly'] = 'Change Plan';
      this.premiumPlans.basic.btnName['yearly'] = 'Downgrade';
      this.premiumPlans.advanced.btnName['yearly'] = 'Downgrade';
      this.premiumPlans.advancedPlus.btnName['yearly'] = 'Cancel';
    }

    this.cdr.detectChanges();
  }

  async popupStoreFront(premiumType: PremiumPlan) {
    // This function is called when the user clicks on the "Checkout" button
    // It uses the premium builder API to open the popup store
    // It also adds the items in the cart to the popup store
    // It also adds the tags to the popup store
    // It also resets the popup store
    // if (typeof Paddle !== 'undefined') { // && this.guild.premium_lv === 0) {
    this.premiumButtonDisabled = true;
    if (typeof Paddle !== 'undefined' && this.guild.premium_lv === 0) {
      this.userEmail = this.appComponent.email;
      if (this.userId == undefined || this.guildId == undefined) {
        this.premiumButtonDisabled = false;
        return;
      }

      const checkoutConfig = {
        items: [
          {
            priceId: premiumType.price_id[this.selectedTab],
            quantity: 1,
          },
        ],
        customData: {
          user_id: this.userId.toString(),
          guild_id: this.guildId.toString(),
        },
      };

      if (typeof this.userEmail === 'string' && this.userEmail.length > 0) {
        checkoutConfig['customer'] = {
          email: this.userEmail,
        };
      }

      Paddle.Checkout.open(checkoutConfig);
    } else if (this.guild.premium_lv > 0) {
      if (premiumType.btnName[this.selectedTab] === 'Cancel') {
        await this.cancelSubscription(premiumType);
      } else {
        await this.doPlanChange(premiumType);
      }
    }
    this.premiumButtonDisabled = false;
  }

  async cancelSubscription(premiumType: PremiumPlan) {
    return new Promise<void>((resolve, reject) => {
      this.botService.subscriptionCancel(this.premiumInfo.sub_id, () => {
        this.premiumInfo.status = 'canceled';
        resolve();
      });
    });
  }

  async doPlanChange(premiumType: PremiumPlan) {
    return new Promise<void>((resolve, reject) => {
      this.botService.subscriptionPlanUpdateRequest(
        this.premiumInfo.sub_id,
        premiumType.price_id[this.selectedTab],
        (payload: SubscriptionPlanUpdate): void => {
          // print the response from the Paddle API
          const dialogRef = this.dialog.open(DialogElementsExampleDialog, {
            autoFocus: false,
            data: payload,
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result && result.confirmed) {
              this.botService.subscriptionPlanUpdate(
                this.premiumInfo.sub_id,
                premiumType.price_id[this.selectedTab],
                payload.update_summary.result.action,
                () => {
                  this.guild.premium_lv = premiumType.level;
                  this.premiumInfo.price_id =
                    premiumType.price_id[this.selectedTab];
                  this.updateButtonNames();
                  resolve();
                }
              );
            } else {
              // Nothing
              reject();
            }
          });
          resolve();
        }
      );
    });
  }

  reactivateSubscription(): void {
    this.botService.subscriptionReactivate(this.premiumInfo.sub_id, () => {
      this.premiumInfo.status = 'active';
    });
  }
}

@Component({
  selector: 'app-premium-card',
  templateUrl: './dialog-elements-example-dialog.html',
  styleUrls: ['./premium-card.component.css'],
})
export class DialogElementsExampleDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionPlanUpdate,
    public dialogRef: MatDialogRef<DialogElementsExampleDialog>
  ) {}

  ngOnInit() {
    this.data.update_summary.charge.amount = (
      parseFloat(this.data.update_summary.charge.amount) / 100
    ).toString();
    this.data.update_summary.credit.amount = (
      parseFloat(this.data.update_summary.credit.amount) / 100
    ).toString();
    this.data.update_summary.result.amount = (
      parseFloat(this.data.update_summary.result.amount) / 100
    ).toString();

    const date = new Date(this.data.items[0].next_billed_at);
    const userLocale = navigator.language || 'en-US'; // Get user's locale or fallback to 'en-US'
    this.data.items[0].next_billed_at = date.toLocaleDateString(userLocale);
  }

  confirm(): void {
    // Close the dialog and pass data back to the parent component
    this.dialogRef.close({ confirmed: true, payload: this.data });
  }

  cancel(): void {
    // Close the dialog without passing any data
    this.dialogRef.close({ confirmed: false });
  }

}
