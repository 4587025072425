import { Component, OnInit } from '@angular/core';
import * as env from '../environments/environment';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { ActivatedRoute } from '@angular/router';
import { DiscordResourceService } from './services/discord-resource.service';
import { UserService } from './services/user.service';
import $ from 'jquery';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  avatarSrc = '';
  userName = '';
  title = '';
  private userIdSubject = new BehaviorSubject<number>(0);
  userId = this.userIdSubject.asObservable();
  email = '';

  environment = env.environment;

  constructor(
    public route: ActivatedRoute,
    private oauthService: OAuthService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.oauthService.configure(env.authConfig);
    this.oauthService.setStorage(localStorage);

    this.oauthService.tryLogin().then(() => {
      if (this.isLoggedIn) {
        return this.oauthService
          .loadUserProfile()
          .then((userInfo: UserInfo) => {
            this.userService.setUserInfo(userInfo);
            this.userName = userInfo.username;
            if (userInfo.info.avatar)
              this.avatarSrc = `https://cdn.discordapp.com/avatars/${userInfo.info.id}/${userInfo.info.avatar}.png?size=32`;
            // this.userId = userInfo.info.id;
            this.setUserId(userInfo.info.id);
            this.email = userInfo.info.email;
          });
      }

      return Promise.resolve(undefined);
    });
    // this.setupBackToTop();
  }

  private setUserId(userId: number): void {
    this.userIdSubject.next(userId);
  }

  private setupBackToTop(): void {
    const $btn = $('#back-to-top');
    const $nav_fixed = $('.site-header .navbar.fixed-top');

    if ($nav_fixed.length > 0) {
      $('body').addClass('has-nav-fixed');
    }

    $(window).scroll(() => {
      $(window).scrollTop() > 200
        ? $nav_fixed.addClass('bg-dark')
        : $nav_fixed.removeClass('bg-dark');
    });

    $(window).on('load', () => {
      $(window).trigger('scroll');
    });

    $(window).scroll(() =>
      $(window).scrollTop() > 300 ? $btn.fadeIn() : $btn.fadeOut()
    );

    $btn.click((e) => {
      $('html,body').animate({ scrollTop: 0 }, 400);
      e.preventDefault();
      return false;
    });
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  // getUserId(): number {
  //   return this.userId;
  // }

  login(): void {
    this.oauthService.initCodeFlow();
  }

  logout(): void {
    this.oauthService.logOut();
  }

  currentYearLong(): number {
    return new Date().getFullYear();
  }

  get isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  scrollToTop(): void {
    window.scroll(0, 0);
  }
}
