import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { BotGuild, ServerInfo } from 'src/app/models/bot/guild';
import { shareReplay, take } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { GuildService } from 'src/app/services/guild.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-server-card',
  templateUrl: './server-card.component.html',
  styleUrls: ['./server-card.component.css'],
})
export class ServerCardComponent implements OnInit {
  faIcons = {
    trash: faTrashAlt,
  };

  guildId: string;
  guild: BotGuild;
  timezones: string[] = moment.tz.names();

  @Input() serverInfo$: Observable<ServerInfo>;

  constructor(
    private route: ActivatedRoute,
    private guildService: GuildService,
    private botService: BotResourceService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');

    this.guildService.getGuildData(this.guildId).subscribe((guild) => {
      this.guild = guild;
    });

    this.serverInfo$ = this.botService
      .getGuildServer(this.guildId)
      .pipe(shareReplay(1));
  }

  setAdminRole(eventTarget: EventTarget): void {
    // console.log((eventTarget as HTMLInputElement));
    this.botService.sendCommand(
      this.guildId,
      'admin_role set',
      (eventTarget as HTMLInputElement).value
    );
  }
}
