<div class="container-fluid">
  <div class="row pt-3 ml-3">
    <div class="col d-flex justify-content-center">
      <h1>Serverwide Settings</h1>
    </div>
  </div>
  <div *ngIf="guild" class="pb-3 pt-2">
    <div *ngIf="!guild.is_admin" class="alert alert-warning h5">
      &#x26A0; Only the <a href="https://docs.submitty.app/getting-started/terms#server-owner" target="_blank">server owner</a>,
      <a href="https://docs.submitty.app/getting-started/terms#administrator" target="_blank">administrators</a> or users with the
      <a href="https://docs.submitty.app/getting-started/terms#administrator-role" target="_blank">admin role</a> of the server
      can change those settings!
    </div>

    <ng-container *ngIf="serverInfo$ | async as serverInfo">
      <h4>Role Settings</h4>
      <div class="row">
        <div class="col">
          <form class="adminRoleSelection">
            <mat-form-field class="full-width-container" floatLabel="always">
              <mat-label for="adminRoleSelection">Admin role</mat-label>
              <mat-select
                      [(ngModel)]="serverInfo.admin_role"
                      name="organizerRole"
                      id="adminRoleSelection"
                      [disabled]="!guild.is_admin"
                      (selectionChange)="setAdminRole($event)"
                      placeholder="Select a role"
                      >
                <ng-container *ngFor="let role of serverInfo.roles">
                  <mat-option [value]="role.id"
                          [disabled]="role.id === guild.id" [selected]="role.id === serverInfo.admin_role">
                    {{ role.name }}
                  </mat-option>
                </ng-container>
              </mat-select>
              <div class="mat-select-link-wrapper">
                <a href="https://docs.submitty.app/getting-started/terms#administrator-role" target="_blank" class="mat-select-link">What is the Admin Role?</a>
              </div>
            </mat-form-field>
          </form>
        </div>
      </div>

      <!-- <h4 class="mt-3">Language Settings</h4>
      <div class="row">
        <div class="col">
          <form class="LanguageSelection">
            <mat-form-field class="full-width-container" floatLabel="always">
              <mat-label for="LanguageSelection">Language</mat-label>
              <mat-select [(ngModel)]="serverInfo.locale" name="language" id="LanguageSelection" [disabled]="!guild.is_admin" (selectionChange)="setLanguage($event.value)">
                <mat-option *ngFor="let language of serverInfo.languages" [value]="language" [selected]="language === serverInfo.locale">
                  {{ language }}
                </mat-option>
              </mat-select>
              <div class="mat-select-link-wrapper">
                <a href="https://discord.gg/9JheGp8fVY" target="_blank" class="mat-select-link">
                  Help us translate
                </a>
              </div>
            </mat-form-field>
          </form>
        </div>
      </div> -->
    </ng-container>
  </div>
</div>
