<div class="pt-3 ml-3 text-center">
  <h1>Submitty Premium</h1>
</div>

<div class="row justify-content-center" *ngIf="guild">
  <div class="col-8">
      <div class="btn-group btn-group-lg w-100 " role="group" aria-label="Basic example">
        <button type="button" class="btn btn-secondary" [class.active]="selectedTab === 'monthly'" (click)="selectTab('monthly')">Monthly</button>
        <button type="button" class="btn btn-secondary" [class.active]="selectedTab === 'yearly'" (click)="selectTab('yearly')">Yearly</button>
      </div>
  </div>
</div>
<div class="row justify-content-center text-center pt-3" *ngIf="guild && guild.premium_lv && premiumInfo">
  <p class="">
    Premium paid by <i>{{premiumInfo.user_name}}</i><br/>
    {{ premiumInfo.status === "active" ? "Next billing cycle: " + next_billing : "Status: " + premiumInfo.status + ". Active till " + next_billing}}
  </p>
</div>
<div class="premiumContainer" *ngIf="guild">
  <div class="row justify-content-center pt-3">
    <div class="col-sm-12 col-md-5 col-xl-3 shadow-lg p-3 mb-5 rounded m-2 premiumCol"
      *ngFor="let planKey of premiumPlanKeys" [ngClass]="{
        'active': guild.premium_lv === premiumPlans[planKey].level && premiumInfo.price_id === premiumPlans[planKey].price_id[selectedTab],
        'semi-active': guild.premium_lv === premiumPlans[planKey].level && premiumInfo.price_id !== premiumPlans[planKey].price_id[selectedTab]
      }">
      <div class="active-indicator" *ngIf="guild.premium_lv === premiumPlans[planKey].level">
        <p class="active-label">Active</p>
      </div>
      <h1 class="premiumName">{{ premiumPlans[planKey].name }}</h1>
      <h3 class="ppm">{{ premiumPlans[planKey].price }}</h3>
      <p class="ppm"> per {{selectedTab === 'monthly' ? 'month' : 'year'}}</p>
      <ul class="has-text-grey-dark is-size-6 mt-3 mb-6 pl-2" style="list-style: none;">
        <li *ngFor="let feature of premiumPlans[planKey].features" class="mb-3 is-flex is-align-items-center"
          [class.checkmark]="feature.enabled" [class.xmark]="!feature.enabled">
          <fa-icon class="pr-1" [icon]="feature.enabled ? faIcons.check : faIcons.cross"></fa-icon>
          {{ feature.name }}
        </li>
      </ul>
      <button class="btn btn-subscribe btn-lg btn-block" [disabled]="premiumButtonDisabled"
        [ngClass]="{'disabled-button': premiumButtonDisabled}" (click)="popupStoreFront(premiumPlans[planKey])">
        {{ premiumPlans[planKey].btnName[selectedTab] }}
      </button>
    </div>
  </div>
  <ng-container *ngIf="premiumInfo.status === 'canceled' && premiumInfo.user_id === userId">
    <div class="row rowoverlay p-3">
      <div class="col-12 coloverlay text-center">
        <!-- Make a row in a row because the outer row needs to be absolute and the inner one relative to make the button middle aligned -->
        <div class="row rowoverlay button-row">
          <div class="col-12">
            <button class="btn btn-subscribe" (click)="reactivateSubscription()">Reactivate</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="premiumInfo.user_id && premiumInfo.user_id !== userId.toString() && guild.is_admin">
    <div class="row rowoverlay p-3">
      <div class="col-12 coloverlay text-center">
        <!-- Make a row in a row because the outer row needs to be absolute and the inner one relative to make the button middle aligned -->
        <div class="row rowoverlay button-row">
          <div class="col-12">
            <button class="btn btn-inactive">Subscription managed by<br />{{premiumInfo.user_name}}</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="premiumInfo.user_id !== userId.toString() && !guild.is_admin">
    <div class="row rowoverlay p-3">
      <div class="col-12 coloverlay text-center">
        <!-- Make a row in a row because the outer row needs to be absolute and the inner one relative to make the button middle aligned -->
        <div class="row rowoverlay button-row">
          <div class="col-12">
            <button class="btn btn-inactive">You don't have permissions<br />to manage premium!</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>
<div class="" *ngIf="guild">
<p>* This features are not implemented yet! If you need them, please contact me on Discord. I am happy to work on them with your feedback provided. Do not make your purchase
  depending on them!</p>
</div>
      <!-- [disabled]="guild.premium_lv === premiumPlans[planKey].level"> -->
      <!-- [ngClass]="{'disabled-button': guild.premium_lv === premiumPlans[planKey].level}" -->
