<header class="d-flex flex-column flex-md-row align-items-md-center p-5 title-bg-dark">
  <div class="pt-md-3 pb-md-4">
    <h1 class="bd-title mt-0">Privacy information</h1>
    <p class="bd-lead">What data do we store, why do we store it and how is it secured?</p>
  </div>
</header>

<section class="p-5 guild-bg-dark">
  <h2>First of all:</h2>
  <p>
    Security and data safety is important to us. Also the privacy of our users.
    That is why we try to store as little data as possible to operate our service.
  </p>
  <h2>What data do we store and why?</h2>
    <u>User specific data:</u>
    <ul>
      <li>
        Discord User ID.
        <ul>
          <li>To know for which submission you have voted.</li>
          <li>To know your notification preferences.</li>
          <li>To know which submission is created by you.</li>
        </ul>
      </li>
    </ul>
    <u>Message Content:</u>
    <ul>
      <li>The content of messages that are submissions.</li>
    </ul>

  <h2>How do we use your data?</h2>
  <p>We use this data to:</p>
  <ul>
    <li>To keep track of how many votes a submission has.</li>
    <li>Update submissions correctly on vote</li>
    <li>Keep track which submission is created by which user</li>
  </ul>

  <h2>How do we store your data?</h2>
  <p>
    We store the data on a server in Frankfurt. Authentication to the server is only allowed via SSH-Key.<br>
    The data itself is stored in a password protected database.
  </p>

  <h2>Can I delete my data?</h2>
  <p>
    In short: <b>Yes!</b><br>
    However, we want to point out a few things:
  </p>
    <ul>
      <li>Your data is only stored as long as needed. For example if a submission gets closed/deleted, all data related to that
    submission will be removed.</li>
      <li>In case you are an admin, all submission/user/server related information will be deleted when you kick the bot from the
      server.</li>
      <li>We also want to point out that deleting your data will not update all submissions you have voted on. This means that the
      vote count will only change on the next user vote.</li>
      <li>And last but not least: Deleting your data is irreversible.</li>
    </ul>

  <p>
    That said; If you want that we erase your data send us a request. We will remove all your entries from the database. However, this option might take some time since we need
      to manually trigger this action (up to 5 working days).
  </p>

  <h2>Questions?</h2>
  <p>
    If you have any questions about the data protection rights, feel free to contact us via:<br>
    E-Mail: <a href="mailto:admin@submitty.app">admin@submitty.app</a><br>
    Discord Server: <a href="https://discord.submitty.app" target="_blank">Submitty</a>
  </p>
</section>
