<ng-container *ngIf="guilds && guilds.length > 0">
  <div class="hr-bg-dark"></div>
  <div>
    {{ helpText }}
  </div>
  <div class="row">
    <div *ngFor="let guild of guilds" class="card card-bg-dark m-3">
      <img *ngIf="guild.id && guild.icon; else defaultIcon" class="card-img-top" alt=""
        src="https://cdn.discordapp.com/icons/{{ guild.id }}/{{ guild.icon }}.png?size=256" >
      <div class="card-body">
        <h5 class="card-title">{{ guild.name }}</h5>
        <button *ngIf="buttonText && !guild.joined" type="button" class="btn btn-primary" (click)="selectGuild(guild.id)">
          {{ buttonText }}
        </button>
        <button *ngIf="guild.joined" type="button" class="btn btn-primary" routerLink="/guilds/{{guild.id}}" (click)="setGuild(guild)">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #defaultIcon>
  <img class="card-img-top" alt="" src="https://cdn.discordapp.com/icons/{{ submittyGuild.id }}/{{ submittyGuild.icon }}.png?size=256">
</ng-template>
