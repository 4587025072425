<header class="d-flex flex-column flex-md-row align-items-md-center p-5 title-bg-dark">
  <div class="pt-md-3 pb-md-4">
    <h1 class="bd-title mt-0">Manage Your Servers</h1>
    <p class="bd-lead">Select a server you want to manage!</p>
  </div>
</header>

<div class="row search-result guild-bg-dark">
  <div class="col pl-5 pr-5">
    <ng-container *ngIf="allGuilds$ | async as guilds; else loadingTemplate">
      <input type="text" [(ngModel)]="filterText" (input)="filterGuilds()" placeholder="Filter guilds" class="mt-3">
      <ng-container *ngIf="filteredGuilds$ | async as guildFiltered">
        <ng-container *ngIf="guildFiltered.length > 0; else loadingTemplate">
          <div class="guild_box">
              <app-guild-cards
              helpText="&#x2705; Congratulations, Submitty is already on your server! Start interacting now."
              [guilds]="interactableGuilds$ | async"
              buttonText="Let's Goooo">
              </app-guild-cards>

              <app-guild-cards
                helpText="&#x26A0; Submitty is not on the server, but you are allowed to invite him. Do it now! 😄 "
                [guilds]="invitableGuilds$ | async" buttonText="Invite Me" (guildSelected)="botAccess($event)">
              </app-guild-cards>

              <app-guild-cards
                helpText="&#x26A0; Unfortunately Submitty is not on the server, neither do you have rights to invite him. Please contact an owner or admin."
                [guilds]="notJoinableGuilds$ | async">
              </app-guild-cards>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="d-flex align-items-center justify-content-center guild_loader_box">
    <div class="guild_loader">
      <div class="guild_loader__bar"></div>
      <div class="guild_loader__bar"></div>
      <div class="guild_loader__bar"></div>
      <div class="guild_loader__bar"></div>
      <div class="guild_loader__bar"></div>
      <div class="guild_loader__ball"></div>
    </div>
  </div>
</ng-template>
