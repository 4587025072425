
<h2 mat-dialog-title>Change Subscription Plan</h2>
<mat-dialog-content>
  <p>On confirmation you will be charged the following amount:</p>
  <p>
  New Plan: {{ data.update_summary.charge.amount }} {{ data.update_summary.charge.currency_code }}<br/>
  Credit: {{ data.update_summary.credit.amount }} {{ data.update_summary.credit.currency_code }}</p>
  <hr/>
  <p>Charged Now: {{ data.update_summary.result.action === "charge" ? data.update_summary.result.amount + " " +
    data.update_summary.result.currency_code : 0 }}</p>
  <p>Next Charge: {{ data.items[0].next_billed_at }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn-subscribe btn-confirm" (click)="confirm()">Confirm</button>
  <button mat-button mat-dialog-close color="primary" (click)="cancel()">Cancel</button>
</mat-dialog-actions>
