import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BotGuild } from '../../../models/bot/guild';
import { GuildService } from 'src/app/services/guild.service';

@Component({
  selector: 'app-guild-cards',
  templateUrl: './guild-cards.component.html',
  styleUrls: ['./guild-cards.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuildCardsComponent {

  @Input()
  helpText: string;

  @Input()
  buttonText: string;

  @Input()
  guilds: BotGuild[];

  @Input()
  submittyGuild: BotGuild;

  constructor(
    private guildService: GuildService,
  ) {
  }

  ngOnInit() {
    this.submittyGuild = this.guildService.submittyGuild
  }


  @Output()
  guildSelected: EventEmitter<string> = new EventEmitter<string>();

  selectGuild(guildId: string): void {
    this.guildSelected.emit(guildId);
  }

  setGuild(guild: BotGuild): void {
    this.guildService.guild = guild;
  }

}
