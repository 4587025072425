import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BotGuild, ChannelConfig, CustomEmoji, GearInfo, GuildOverview, GuildPremium, ServerInfo, UserConfig } from '../models/bot/guild';
import { StatisticsData } from '../models/bot/statistics';
import { Me } from '../models/bot/me';
import { catchError, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { error } from 'console';

@Injectable({
  providedIn: 'root',
})
export class BotResourceService {
  constructor(private http: HttpClient, private toastr: ToastrService) {}

  getPersonalData(): Observable<Me> {
    return this.http.get<Me>('api/me');
  }

  getGuildsList(): Observable<BotGuild[]> {
    var ret = this.http.get<BotGuild[]>('api/internal/guilds');
    console.log(ret);
    return ret;
  }

  getGuild(guildId: string): Observable<BotGuild> {
    return this.http
      .get<BotGuild>('api/internal/guilds', { params: { guild_id: guildId } })
      .pipe(
        catchError((error) => {
          const errorMessage = error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }

  getPremiumInfo(guildId: string): Observable<GuildPremium> {
    return this.http
      .get<GuildPremium>(`api/internal/guild/${guildId}/premium`)
      .pipe(
        catchError((error) => {
          const errorMessage = error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }

  postCommand(
    guildId: string,
    command: string,
    value: string,
    groupId?: string
  ): Observable<{ success: boolean }> {
    return this.http.put<{ success: boolean }>(
      `api/internal/guild/${guildId}`,
      { command: command, value: value, channel_id: groupId }
    );
  }

  postSubmissionGroupCommand(
    guildId: string,
    command: string,
    value: string,
    subGroupId?: string
  ): Observable<{ success: boolean }> {
    return this.http.put<{ success: boolean }>(
      `api/internal/guild/${guildId}/groups/${subGroupId}`,
      { command: command, value: value }
    );
  }

  getStatisticsData(): Observable<StatisticsData> {
    return this.http.get<StatisticsData>('api/internal/statistics');
  }

  exchangeGear(
    guildId: string,
    exchangeService: string,
    reminder: number
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>('api/internal/guild', {
      guild_id: guildId,
      command: 'gears guild consume',
      value: exchangeService,
      reminder: reminder,
    });
  }

  getGuildOverview(guildId: string): Observable<any> {
    return this.http
      .get<GuildOverview>(`api/internal/guild/${guildId}/overview`)
      .pipe(
        catchError((error) => {
          const errorMessage = error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }

  getGuildServer(guildId: string): Observable<any> {
    return this.http
      .get<ServerInfo>(`api/internal/guild/${guildId}/server`)
      .pipe(
        catchError((error) => {
          const errorMessage = error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }

  getGuildGroup(guildId: string, groupId: string): Observable<any> {
    return this.http
      .get<ChannelConfig>(`api/internal/guild/${guildId}/groups/${groupId}`)
      .pipe(
        catchError((error) => {
          const errorMessage = error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }

  getGuildEmoji(guildId: string): Observable<any> {
    return this.http
      .get<CustomEmoji>(`api/internal/guild/${guildId}/emojis`)
      .pipe(
        catchError((error) => {
          const errorMessage = error?.error?.error || error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }


  gearExchange(
    guilId: string,
    view_value: string,
    value: string,
    reminder: number,
    actionCallback?: () => void
  ): void {
    // console.log('gearExchange', guilId, value, reminder)
    this.exchangeGear(guilId, value, reminder)
      .pipe(take(1))
      .subscribe(
        () => {
          this.toastr.success('Saved successfully', view_value, {
            timeOut: 2000,
          });
          if (actionCallback) {
            actionCallback();
          }
        },
        (error) => {
          const errorMessage =
            error?.error?.error || 'Unknown error. Could not save.';
          this.toastr.error(errorMessage, view_value, { timeOut: 2000 });
        }
      );
  }

  sendCommand(
    guilId: string,
    command: string,
    value: string,
    groupId?: string,
    actionCallback?: () => void
  ): void {
    // console.log('sendCommand', guilId, command, value, groupId)
    this.postCommand(guilId, command, value, groupId)
      .pipe(take(1))
      .subscribe(
        () => {
          this.toastr.success('Saved successfully', command, { timeOut: 2000 });
          if (actionCallback) {
            actionCallback();
          }
        },
        (error) => {
          const errorMessage =
            error?.error?.error || 'Unknown error. Could not save.';
          this.toastr.error(errorMessage, command, { timeOut: 2000 });
        }
      );
  }

  sendSubmissionGroupCommand(
    guilId: string,
    command: string,
    value,  // TODO: Ignore this for now because we pass both strings and lists
    subGroupId: string,
    successCallback?: () => void,
    errorCallback?: () => void
  ): void {
    this.postSubmissionGroupCommand(guilId, command, value, subGroupId)
      .pipe(take(1))
      .subscribe(
        () => {
          this.toastr.success('Saved successfully', command, { timeOut: 2000 });
          if (successCallback) {
            successCallback();
          }
        },
        (error) => {
          const errorMessage = error?.error?.error || 'Unknown error. Could not save.';
          this.toastr.error(errorMessage, command, { timeOut: 2000 });
          if (errorCallback) {
            errorCallback();
          }
        }
      );
  }

  subscriptionPlanUpdateRequest(
    sub_id: string,
    new_price_id: string,
    successCallback?: (payload) => void,
    errorCallback?: () => void
  ): void {
    this.http
      .post<{ success: boolean }>(
        `api/internal/paddle/subscription/change/preview`,
        {
          sub_id: sub_id,
          new_price_id: new_price_id,
        }
      )
      .pipe(take(1))
      .subscribe(
        (payload) => {
          if (successCallback) {
            successCallback(payload);
          }
        },
        (error) => {
          if (errorCallback) {
            errorCallback();
          }
          const errorMessage = error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
        }
      );
  }

  subscriptionCancel(sub_id: string, successCallback?: () => void): void {
    this.http
      .post<{ success: boolean }>(`api/internal/paddle/subscription/cancel`, {
        sub_id: sub_id,
      })
      .pipe(take(1))
      .subscribe(
        () => {
          this.toastr.success('Subscription canceled', '', { timeOut: 2000 });
          if (successCallback) {
            successCallback();
          }
        },
        (error) => {
          const errorMessage =
            error?.error || 'Unknown error. Could not cancel.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
        }
      );
  }

  subscriptionReactivate(sub_id: string, successCallback?: () => void): void {
    this.http
      .post<{ success: boolean }>(`api/internal/paddle/subscription/reactivate`, {
        sub_id: sub_id,
      })
      .pipe(take(1))
      .subscribe(
        () => {
          this.toastr.success('Subscription reactivated', '', { timeOut: 2000 });
          if (successCallback) {
            successCallback();
          }
        },
        (error) => {
          const errorMessage =
            error?.error || 'Unknown error. Could not reactivate.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
        }
      );
  }

  subscriptionPlanUpdate(
    sub_id: string,
    new_price_id: string,
    action: string,
    successCallback?: () => void
  ): void {
    this.http
      .post<{ success: boolean }>(
        `api/internal/paddle/subscription/change/update`,
        {
          sub_id: sub_id,
          new_price_id: new_price_id,
          action: action,
        }
      )
      .pipe(take(1))
      .subscribe(
        () => {
          this.toastr.success('Subscription updated', '', { timeOut: 2000 });
          if (successCallback) {
            successCallback();
          }
        },
        (error) => {
          const errorMessage =
            error?.error || 'Unknown error. Could not update.';
          this.toastr.error(errorMessage, '', { timeOut: 2000 });
        }
      );
  }
  //  {

  //   // subscription_id = sub_id;
  //   // items {
  //   //   price_id: string
  //   //   quantity: 1
  //   // }
  //   // custom_data;
  //   // proration_billing_mode = 'prorated_immediately';
  //   // on_payment_failure = 'prevent_change';
  // }

  getGuildUser(guildId: string) {
    return this.http.get<UserConfig>('api/internal/guild/user', {
      params: { guild_id: guildId },
    });
  }

  getGuildGear(guildId: string): Observable<GearInfo> {
    return this.http
      .get<GearInfo>('api/internal/guild/gears', {
        params: { guild_id: guildId },
      })
      .pipe(
        catchError((error) => {
          const errorMessage =
            error?.error || 'Unknown error. Could not fetch gear info.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }
}
