<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0/css/bootstrap.min.css" />
</head>
<ng-container class="bg">
  <!-- Banner style -->
  <section id="home_banner">
    <div class="hero-content ">
      <div class="container">
        <div class="row z-2 position-relative">
          <div class="col-md-6 my-auto text-center text-md-start pt-4 pt-md-0 order-2 order-md-1">
            <div class="hero-content-wrap">
              <h1 class="hero-title fw-semibold text-white mb-xl-5 mb-4">Submitty</h1>
              <p class="hero-desc text-light mb-xl-4 mb-3">A Discord bot to collect and manage submissions!</p>
              <p class="hero-desc text-light"><span class="text-info">Submitty</span> is a Discord bot that helps you to manage
                any kind of user submissions. Organize your user's feature requests, bug reports, or any other request.
                <span class="text-info">Submitty</span> is the ideal bot to grow your community, listen to your users, and improve your project.</p>
              <button *ngIf="!isLoggedIn" class="btn btn-primary mt-4" (click)="login()">Login & Invite</button>
              <button *ngIf="isLoggedIn" class="btn btn-primary mt-4" routerLink="guilds">Let's Get Started</button>
            </div>
            <div id="above_back" data-relative-input="true" class="above-back-mockup">
              <div data-depth="0.1" class="mockup-img">
                <img class="img-fluid " src="/assets/images/bot_icon.png" alt="">
              </div>
            </div>
          </div>
          <div class="col-md-6 pb-md-0 order-1 order-md-2">
            <!-- Parallax Mouse Move -->
            <div *ngIf="shouldShowParallaxEffect()" id="scene" data-relative-input="true" class="scene hero-mockup">
              <div data-depth="0.6" class="mt-md-n5 banner-img-1" style="margin-left: 80px;"><img
                  class="img-fluid rounded-4 shadow-lg" src="/assets/images/server_config.png" alt=""></div>
              <div data-depth="0.3" class="top-50 start-0 ms-n3 mt-md-5 banner-img-2"><img
                  class="img-fluid rounded-4 shadow-lg" src="/assets/images/submission.png" alt=""></div>
              <div data-depth="0.4" class="top-50 start-50 mt-n4 banner-img-3"><img
                  class="img-fluid rounded-4 shadow-lg" src="/assets/images/submit_a_request.png" alt=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Banner style end -->
  <!-- Statistic style -->
  <section id="statistic" class="statistic-section">
  <!-- Statistic style -->
  <section id="statistic" class="statistic-section">
    <div class="container">
      <div *ngIf="statisticsData$ | async as statisticsData; else no_statistics">
        <div class="row g-4">
          <div class="col-6 col-lg-3" data-aos="fade-up">
            <div class="sts-wrap py-3 position-relative overflow-hidden">
              <div class="hero-mask rounded-4 sts-bg-dark position-absolute top-0 left-0 w-100 h-100">
              </div>
              <div class="sts-content text-center z-2 position-relative py-md-4 py-3 px-3 rounded-4">
                <app-statistics name="Server Count" [iconDefinition]="faIcons.server" [toCount]="statisticsData['server_count']" [speed]="1500"></app-statistics>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3" data-aos="fade-up" data-aos-delay="400">
            <div class="sts-wrap py-3 position-relative overflow-hidden">
              <div class="hero-mask rounded-4 sts-bg-dark position-absolute top-0 left-0 w-100 h-100">
              </div>
              <div class="sts-content text-center z-2 position-relative py-md-4 py-3 px-3 rounded-4">
                <app-statistics name="Submissions Created" [iconDefinition]="faIcons.calendarCheck"  [toCount]="statisticsData['total_submissions']" [speed]="1500"></app-statistics>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3" data-aos="fade-up" data-aos-delay="600">
            <div class="sts-wrap py-3 position-relative overflow-hidden">
              <div class="hero-mask rounded-4 sts-bg-dark position-absolute top-0 left-0 w-100 h-100">
              </div>
              <div class="sts-content text-center z-2 position-relative py-md-4 py-3 px-3 rounded-4" matTooltip="{{getClosedCountInfo(statisticsData)}}" matTooltipClass="mat-tooltip" matTooltipPosition="below">
                <app-statistics name="Submissions Closed"  [iconDefinition]="faIcons.users" [toCount]="statisticsData['total_closed_submissions']" [speed]="1500"></app-statistics>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3" data-aos="fade-up" data-aos-delay="200">
            <div class="sts-wrap py-3 position-relative overflow-hidden">
              <div class="hero-mask rounded-4 sts-bg-dark position-absolute top-0 left-0 w-100 h-100">
              </div>
              <div class="sts-content text-center z-2 position-relative py-md-4 py-3 px-3 rounded-4">
                <app-statistics name="Shard count" [iconDefinition]="faIcons.shards" [toCount]="statisticsData['shard_count']" [speed]="1500"></app-statistics>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- No connection to backend -->
      <ng-template #no_statistics>
        <div class="row g-4">
          <div class="col-6 col-lg-3" data-aos="fade-up">
            <div class="sts-wrap py-3 position-relative overflow-hidden">
              <div class="hero-mask rounded-4 sts-bg-dark position-absolute top-0 left-0 w-100 h-100">
              </div>
              <div class="sts-content text-center z-2 position-relative py-md-4 py-3 px-3 rounded-4">
                <app-statistics name="Server Count" [iconDefinition]="faIcons.server" [toCount]="0" [speed]="1500"></app-statistics>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3" data-aos="fade-up" data-aos-delay="400">
            <div class="sts-wrap py-3 position-relative overflow-hidden">
              <div class="hero-mask rounded-4 sts-bg-dark position-absolute top-0 left-0 w-100 h-100">
              </div>
              <div class="sts-content text-center z-2 position-relative py-md-4 py-3 px-3 rounded-4">
                <app-statistics name="Submissions Created" [iconDefinition]="faIcons.calendarCheck"  [toCount]="0" [speed]="1500"></app-statistics>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3" data-aos="fade-up" data-aos-delay="600">
            <div class="sts-wrap py-3 position-relative overflow-hidden">
              <div class="hero-mask rounded-4 sts-bg-dark position-absolute top-0 left-0 w-100 h-100">
              </div>
              <div class="sts-content text-center z-2 position-relative py-md-4 py-3 px-3 rounded-4">
                <app-statistics name="Submissions Closed"  [iconDefinition]="faIcons.users" [toCount]="0" [speed]="1500"></app-statistics>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3" data-aos="fade-up" data-aos-delay="200">
            <div class="sts-wrap py-3 position-relative overflow-hidden">
              <div class="hero-mask rounded-4 sts-bg-dark position-absolute top-0 left-0 w-100 h-100">
              </div>
              <div class="sts-content text-center z-2 position-relative py-md-4 py-3 px-3 rounded-4">
                <app-statistics name="Shard count" [iconDefinition]="faIcons.shards" [toCount]="0" [speed]="1500"></app-statistics>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
  <!-- Statistic style end -->
  </section>
  <!-- Statistic style end -->
  <!-- Feature style -->
  <section id="feature" class="feature-section">
    <div class="container">
      <!-- Heading -->
      <div class="position-relative d-flex text-center mb-5">
        <h2 class="heading-text-light text-white-50 text-uppercase fw-semibold w-100 mb-0">Why Submitty?</h2>
        <p class="heading-title text-white fw-semibold position-absolute w-100 mb-0 lh-base align-self-center">
          Why Submitty?
          <span class="divider border-bottom border-3 border-secondary d-block mx-auto"></span>
        </p>
      </div>
      <!-- Heading end-->
      <div class="feature-steps">
        <div class="row position-relative">
          <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex aos-init aos-animate" data-aos="fade-up">
            <a href="https://docs.submitty.app/getting-started" target="_blank">
              <div class="feature-box">
                <div class="icon-img">
                  <img src="/assets/images/plan.png" alt="">
                </div>
                <h5 class="icon-box-title text-white fw-semibold mb-3">Easy Setup</h5>
                <p>Basic setup is easy and can be done with only one command!</p>
              </div>
            </a>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex aos-init aos-animate" data-aos="fade-up">
            <a href="https://docs.submitty.app/getting-started/manual-setup" target="_blank">
              <div class="feature-box fb-2">
                <div class="icon-img">
                  <img src="/assets/images/calendar.png" alt="">
                </div>
                <h5 class="icon-box-title text-white fw-semibold mb-3">Custom Workflow</h5>
                <p>Customize your submission workflow. Do they need approval? Should there be a notification? Do you want a discussion thread attached?</p>
              </div>
            </a>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex aos-init aos-animate" data-aos="fade-up">
            <a href="https://docs.submitty.app/api/connection/" target="_blank">
              <div class="feature-box fb-3">
                <div class="icon-img">
                  <img src="/assets/images/voice-command.png" alt="">
                </div>
                <h5 class="icon-box-title text-white fw-semibold mb-3">Third Party Integration</h5>
                <p>Let <span class="text-info">Submitty</span> create issues on GitHub, GitLab and more.</p>
              </div>
            </a>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex aos-init aos-animate" data-aos="fade-up">
            <a href="https://uptime.submitty.app" target="_blank">
              <div class="feature-box">
                <div class="icon-img">
                  <img src="/assets/images/clock.png" alt="">
                </div>
                <h5 class="icon-box-title text-white fw-semibold mb-3">>99.9% Uptime</h5>
                <p><span class="text-info">Submitty</span> is running since 2024 and has a pretty damn good
                  <a href="https://uptime.submitty.app" target="_blank">uptime</a> due to our very
                  stable server <a href="https://hetzner.cloud/?ref=eJn45HQkudQl" target="_blank">host</a>.</p>
              </div>
            </a>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex aos-init aos-animate" data-aos="fade-up">
            <a href="https://docs.submitty.app" target="_blank">
              <div class="feature-box fb-2">
                <div class="icon-img">
                  <img src="/assets/images/documents.png" alt="">
                </div>
                <h5 class="icon-box-title text-white fw-semibold mb-3">Fully Documented</h5>
                <p>Check out our <a href="https://docs.submitty.app" target="_blank">documentation</a> for more information or reach us on our <a href="https://discord.submitty.app" target="_blank">Discord</a>.</p>
              </div>
            </a>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-12 d-flex aos-init aos-animate" data-aos="fade-up">
            <a href="/privacy">
              <div class="feature-box fb-3">
                <div class="icon-img">
                  <img src="/assets/images/secure.png" alt="">
                </div>
                <h5 class="icon-box-title text-white fw-semibold mb-3">Safe</h5>
                <p>We only store data that is needed to operate the bot correctly! The data is stored safely
                  on a server in Europe/Germany.</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Feature style end -->
  <!-- Repeating Events -->
  <section id="about_us" class="about-section">
    <div class="container">
      <!-- Heading -->
      <div class="position-relative d-flex text-center mb-sm-5 mb-4">
        <h2 class="heading-text-light text-white-50 text-uppercase fw-semibold w-100 mb-0">Features</h2>
        <p class="heading-title text-white fw-semibold position-absolute w-100 mb-0 lh-base align-self-center">
          Features
          <span class="divider border-bottom border-3 border-secondary d-block mx-auto"></span>
        </p>
      </div>
      <!-- Heading end-->
      <div class="row align-items-center">
        <div class="col-lg-6 text-lg-start order-lg-2 order-1" data-aos="fade-left">
          <h2 class="main-heading text-white fw-semibold">Discord Threads</h2>
          <p class="abt-sub-desc pb-3">Let <span class="text-info">Submitty</span> attach discussion threads to submissions.</p>
          <p class="abt-desc pb-3">Use threads to keep comments and feedback about submissions in one place.
          </p>
        </div>
        <div class="col-lg-6 position-relative mb-lg-0 mb-4 order-lg-1 order-2" data-aos="fade-right">
          <div data-relative-input="true" class="feature_mockup">
            <div class="position-relative text-lg-end text-center">
              <img src="/assets/images/thread.png" alt="" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Repeating Events end -->
  <!-- Image-Text Section 1 -->
  <section id="img_txt_1" class="img-txt-section pb-5" data-aos="fade-up">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 text-lg-start order-lg-1 order-1">
          <h2 class="main-heading text-white fw-semibold">Comments on Submissions</h2>
          <p class="abt-sub-desc pb-3">Append comments to submissions to make them easy to find.</p>
          <p class="abt-desc pb-3">Users with required permission can add comments directly to a submission.</p>
        </div>
        <div class="col-lg-6 position-relative mb-lg-0 mb-4 order-lg-2 order-2">
          <div class="position-relative text-lg-end text-center">
            <img src="/assets/images/comments.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Image-Text Section 1 end -->
  <!-- Image-Text Section 2 -->
  <section id="img_txt_2" class="img-txt-section py-5" data-aos="fade-up">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 text-lg-start order-lg-2 order-1">
          <h2 class="main-heading text-white fw-semibold">Submission Approval</h2>
          <p class="abt-sub-desc pb-3">Accept and reject submissions with ease!</p>
          <p class="abt-desc pb-3">If enabled, upon submission creation, submissions need to be approved by a stuff member. This
            helps you to
            organize and keep your submission channel clean.
          </p>
          <!-- <a class="btn btn-primary mt-2" href="#">
                    Join Us
                    <span class="ms-2"><i class="fa-solid fa-chevron-right"></i></span>
                  </a> -->
        </div>
        <div class="col-lg-6 position-relative mb-lg-0 mb-4 order-lg-1 order-2">
          <div class="position-relative text-lg-start text-center">
            <img src="/assets/images/approval.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Image-Text Section 2 end -->
  <!-- Image-Text Section 3 -->
  <section id="img_txt_3" class="img-txt-section py-5" data-aos="fade-up">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 text-lg-start order-lg-1 order-1">
          <h2 class="main-heading text-white fw-semibold">Third Party API</h2>
          <p class="abt-sub-desc pb-3">Let <span class="text-info">Submitty</span> create issues on GitHub and GitLab. (More to come)</p>
          <p class="abt-desc pb-3">You can let <span class="text-info">Submitty</span> create, edit and close issues on third party integrations.
          You no longer need to manually drag submissions manually to a third party and keep them in sync.</p>
        </div>
        <div class="col-lg-6 position-relative order-lg-2 order-2 mb-lg-0 mb-4">
          <div class="position-relative text-lg-end text-center">
            <img src="/assets/images/api.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Image-Text Section 3 end -->
  <!-- Join Section -->
  <section id="cta" class="cta-section">
    <div class="container">
      <!-- Heading -->
      <div class="position-relative d-flex text-center">
        <h2 class="heading-text-light text-white-50 text-uppercase fw-semibold lh-1 w-100 mb-0 mb-n1">Start Now
        </h2>
      </div>
      <!-- Heading end-->
      <div class="bg-secondary text-center shadow-lg rounded-4 p-lg-5 p-sm-4 py-4 px-3 cta-box-wrap"
        data-aos="zoom-in-down">
        <div class="lh-1 mb-2 cta-icon"><i class="fa-brands fa-discord"></i></div>
        <h3 class="fw-semibold mb-3 cta-title">Join Our Community</h3>
        <div
          class="d-flex align-items-center justify-content-center flex-md-row flex-column cta-action gap-md-4 gap-2 mt-4">
          <a *ngIf="!isLoggedIn" class="btn btn-light fw-semibold" (click)="login()">Get Submitty</a>
          <a *ngIf="isLoggedIn" class="btn btn-light fw-semibold" routerLink="guilds">Get Submitty</a>
          <a class="btn btn-light fw-semibold" target="_blank" href="https://discord.submitty.app">Join our Discord-Server</a>
        </div>
      </div>
    </div>
  </section>
  <!-- Join Section end -->

  <!-- JS Link -->
  <!-- <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.0/jquery.min.js"></script>
  <script type="text/javascript"
    src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0/js/bootstrap.min.js"></script>
  <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/parallax/3.1.0/parallax.min.js"></script> -->
  <!-- <script type="text/javascript" src="front.js"></script> -->
</ng-container>
