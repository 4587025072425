import { AuthConfig } from 'angular-oauth2-oidc';

export const environment = {
  production: true,
  baseUrlDiscord: 'https://discord.com/api',
};

export const authConfig: AuthConfig = {
  issuer: 'https://discord.com/',
  redirectUri: 'https://submitty.app/',
  clientId: '1222249426182209686',
  dummyClientSecret: 'x7-Rw76jZ1K4hpP04Q2ZYk2KmL6YLa9R',
  responseType: 'code',
  scope: 'identify connections guilds',

  oidc: false,
  loginUrl: 'https://discord.com/api/oauth2/authorize',
  tokenEndpoint: 'https://discord.com/api/oauth2/token',
  userinfoEndpoint: 'https://discordapp.com/api/users/@me',
  revocationEndpoint: 'https://discord.com/api/oauth2/token/revoke',
};
