import { Injectable } from '@angular/core';
import { BotGuild } from '../models/bot/guild';
import { BotResourceService } from './bot-resource.service';
import { Observable } from 'rxjs/internal/Observable';
import { from } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GuildService {
  private id: string;
  guild: BotGuild;
  submittyGuild: BotGuild = {
    id: '1224273597036494929',
    name: 'Submitty',
    icon: '0fc11a275997ff40d227d6731aedf9ae',
    owner: false,
    permissions: 0,
    features: [],
    permissions_new: '0',
    joined: false,
    premium_lv: 0,
    is_admin: false,
    channels: [],
  };
  guildObservable: Observable<BotGuild>;

  constructor(private botService: BotResourceService) {}

  getGuildData(id: string): Observable<BotGuild> {
    if (!this.guildObservable || this.id !== id) {
      this.guildObservable = from(this.botService.getGuild(id)).pipe(
        shareReplay(1)
      );
    }
    this.id = id;
    return this.guildObservable;
  }
}
