import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { PremiumComponent } from './pages/premium/premium.component';
import { GuildSelectionComponent } from './pages/guild-selection/guild-selection.component';
import { GuildDashboardComponent } from './pages/guild-dashboard/guild-dashboard.component';
import { ChannelCardComponent } from './pages/guild-dashboard/channel-card/channel-card.component';
import { ServerCardComponent } from './pages/guild-dashboard/server-card/server-card.component';
import { FastspringCardComponent } from './pages/guild-dashboard/premium/premium-card.component';
import { OverviewCardComponent } from './pages/guild-dashboard/overview-card/overview-card.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  // { path: 'premium', component: PremiumComponent },
  { path: 'guilds', canActivate: [IsLoggedInGuard], component: GuildSelectionComponent },
  {
    path: 'guilds/:id',
    canActivate: [IsLoggedInGuard],
    component: GuildDashboardComponent,
    children: [
      { path: '', component: OverviewCardComponent,},
      { path: 'overview', component: OverviewCardComponent },
      { path: 'server', component: ServerCardComponent },
      { path: 'groups/:groupId', component: ChannelCardComponent },
      { path: 'premium', component: FastspringCardComponent },
    ]
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }
